<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Code - Nickname</label>

            <b-form-select
              v-model="selectNickname"
              :options="formattedProjectOptions"
              size="lg"
              @change="setNickname($event)"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Name</label>

            <b-form-input
              v-model="filter.nickname"
              disabled
              size="lg"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Revision</label>

            <b-form-input
              v-model="filter.revision_version"
              disabled
              size="lg"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-5">
          <div class="d-flex">
            <button class="btn btn-primary btn-md mr-2" @click="getReports()">
              <i class="flaticon2-search-1"></i> Show
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row mb-5">
          <div class="col-md-4 py-2 col-sm-12">
            <vue-excel-xlsx
              :data="dspData"
              :columns="columns"
              :file-name="
                `${filter.code} - ${filter.nickname} - Kelengkapan Document Site Project`
              "
              :sheetname="'sheet1'"
            >
              <button
                class="btn btn-success btn-md mr-2"
                :disabled="dspData.length === 0"
              >
                <i class="fa fa-file-excel"></i>
                Excel
              </button>
            </vue-excel-xlsx>

            <button
              class="btn btn-danger btn-md mr-2"
              @click="onExportPDF"
              :disabled="dspData.length === 0"
            >
              <i class="fa fa-file-pdf"></i>
              PDF
            </button>
          </div>

          <div class="col-md-4 offset-md-4 py-2 col-sm-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="flaticon2-search-1"></i>
                </span>
              </div>

              <input type="text" class="form-control" placeholder="Search" />
            </div>
          </div>
        </div>

        <complete-table :loading="loading" :data="documentSiteProjects">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">No</span>
              </th>
              <th style="min-width: 150px">Category</th>
              <th style="min-width: 150px">Item</th>
              <th style="min-width: 150px">Complete</th>
              <th style="min-width: 150px">Description</th>
              <th style="min-width: 150px">Note</th>
              <th style="min-width: 150px">Date</th>
              <th style="min-width: 150px">Storage</th>
            </tr>
          </template>

          <template #defaultBody>
            <template v-for="(documentSiteProject, i) in documentSiteProjects">
              <tr v-bind:key="i" class="border-0">
                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ i + 1 }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{
                      documentSiteProject.site_document_type
                        ? documentSiteProject.site_document_type.category
                          ? documentSiteProject.site_document_type.category.name
                          : ""
                        : "-"
                    }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{
                      documentSiteProject.site_document_type
                        ? documentSiteProject.site_document_type.item
                        : "-"
                    }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    <b-form-checkbox
                      disabled
                      class="custom-control-inline"
                      v-model="documentSiteProject.complete"
                      value="1"
                    />
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ documentSiteProject.description }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ documentSiteProject.note }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{
                      moment(documentSiteProject.site_document_date).format(
                        "DD-MM-YYYY"
                      )
                    }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ documentSiteProject.storage }}
                  </span>
                </td>
              </tr>
            </template>
          </template>
        </complete-table>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { mapState } from "vuex";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import { GET_PROJECT_DETAIL } from "../../../core/services/store/project.module";
import { GET_SCOPES } from "../../../core/services/store/scope.module";
import { GET_REPORT_DOCUMENT_SITE_PROJECT } from "../../../core/services/store/report-document-site-project.module";
import { showToast } from "../../../core/helper/toast.helper";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { exportExcelColumn } from "@/view/pages/reporting/documentSiteProject";
import moment from "moment";
import { logos } from "@/assets/js/components/logos64bit.js";
import {
  SHOW_PROJECT_INTERFACE,
  SHOW_PROJECT_DESIGN,
  SHOW_PROJECT_PLANNER,
  SHOW_PROJECT_DRAFTER,
  SHOW_PROJECT_KOOR,
  SHOW_PROJECT_ENGINER,
  SHOW_PROJECT_MANAGER,
  SHOW_PROJECT_ADMIN,
} from "@/core/services/store/dl.design.module";

export default {
  components: { CompleteTable },
  data() {
    return {
      exportExcelColumn: exportExcelColumn,
      filter: {
        code: null,
        nickname: null,
        scope: null,
        revision_version: null,
        contract_no: null,
      },
      selectNickname: {
        code: null,
        text: null,
      },
      dspData: [],
      columns: [
        { label: "No", field: "no" },
        { label: "Category", field: "kategori" },
        { label: "Item", field: "item" },
        { label: "Complete", field: "kelengkapan" },
        { label: "Description", field: "description" },
        { label: "Note", field: "note" },
        { label: "Date", field: "site_document_date" },
        { label: "Storage", field: "storage" },
      ],

      data: [
        {
          id: 123,
          site_document_type: {
            code: "asd",
            name: "asbuilt drawing",
            category: {
              code: "asd",
              name: "category name",
            },
          },
          project: {
            code: "LSR123123",
            name: "project name",
            nickname: "makpar 2",
          },
          complete: 0,
          storage: "/path/to/file.txt",
        },
        {
          id: 123,
          site_document_type: {
            code: "asd",
            name: "asbuilt drawing",
            category: {
              code: "asd",
              name: "category name",
            },
          },
          project: {
            code: "LSR123123",
            name: "project name",
            nickname: "makpar 2",
          },
          complete: 1,
          storage: "/path/to/file.txt",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projectDetails.projectDetails?.data,
      scopes: (state) => state.scope.scopes?.data,
      documentSiteProjects: (state) =>
        state.reportDocumentSiteProject.reportDocumentSiteProjects?.data,
      loading: (state) => state.reportDocumentSiteProject.loading,
    }),

    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.projectList)) {
        projects = this.projectList.map((project) => {
          return {
            value: project.code,
            text: project.code + " - " + project.nickname,
          };
        });
      }

      return [{ value: null, text: "Choose Project" }, ...projects];
    },

    projectList() {
      let projectInterface = this.$store.getters.getterProjectInterface;
      let projectDesign = this.$store.getters.getterProjectDesign;
      let projectPlanner = this.$store.getters.getterProjectPlanner;
      let projectDrafter = this.$store.getters.getterProjectDrafter;
      let projectKoor = this.$store.getters.getterProjectKoor;
      let projectEnginer = this.$store.getters.getterProjectEnginer;
      let projectManager = this.$store.getters.getterProjectManager;
      let projectAdmin = this.$store.getters.getterProjectAdmin;

      let combine1 = this.merge(projectInterface, projectDesign, "code");
      let combine2 = this.merge(combine1, projectPlanner, "code");
      let combine3 = this.merge(combine2, projectDrafter, "code");
      let combine4 = this.merge(combine3, projectKoor, "code");
      let combine5 = this.merge(combine4, projectEnginer, "code");
      let combine6 = this.merge(combine5, projectManager, "code");
      let combine7 = this.merge(combine6, projectAdmin, "code");

      return combine7;
    },

    formattedScopeOptions() {
      let scopes = [];

      if (arrayMoreThanOne(this.scopes)) {
        scopes = this.scopes.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Choose Scope" }, ...scopes];
    },
    currentDsp() {
      return this.documentSiteProjects;
    },
  },
  methods: {
    merge(a, b, prop) {
      var reduced = a.filter(
        (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
      );
      return reduced.concat(b);
    },
    onExportPDF() {
      moment.locale("id");
      var totalPagesExp = "{total_pages_count_string}";
      var doc = new jsPDF("landscape");
      doc.setFontSize(11);
      const today = new Date();

      var logo = logos;

      var head = [
        [
          "No",
          "Category",
          "Item",
          "Complete",
          "Storage",
          "Note",
          "Description",
          "Date",
        ],
      ];
      var body = [];
      var projectCode = this.filter.code !== null ? this.filter.code : "-";
      var nickname = this.filter.nickname !== null ? this.filter.nickname : "-";
      var contract_no =
        this.filter.contract_no !== null ? this.filter.contract_no : "-";
      this.dspData.map((c) => {
        const propertyValues = Object.values(c);
        body.push(propertyValues);
      });
      doc.autoTable({
        headStyles: {
          halign: "center",
          valign: "middle",
        },
        head: head,
        body: body,
        didDrawPage: function(data) {
          // Header
          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.addImage(logo, "png", data.settings.margin.left, 10, 25, 15);
          doc.text(
            "Reporting of Site Project Documents",
            data.settings.margin.left + 50,
            25
          );
          doc.setFontSize(8);

          doc.text(
            `Day/Date : ${moment(today).format("dddd")}/ ${moment(today).format(
              "ll"
            )}`,
            data.settings.margin.left + 200,
            15
          );
          doc.text(
            `Time :  ${moment(today).format("LTS")}`,
            data.settings.margin.left + 200,
            20
          );
          doc.text(
            `Contract No: ${contract_no}`,
            data.settings.margin.left + 200,
            25
          );

          doc.text(
            `Project Code: ${projectCode}`,
            data.settings.margin.left + 200,
            30
          );

          doc.text(
            `Project Name: ${nickname}`,
            data.settings.margin.left + 200,
            35
          );

          // Footer
          var str = "Page " + doc.internal.getNumberOfPages();
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === "function") {
            str = str + " of " + totalPagesExp;
          }
          doc.setFontSize(10);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          var footer_lrs = 'PT. LEN RAILWAY SYSTEMS';
          doc.text(str, 260, pageHeight - 10);
          doc.text(footer_lrs, data.settings.margin.left, pageHeight - 10);
        },
        margin: { top: 45 },
      });

      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }

      doc.save(
        `${this.filter.code} - ${this.filter.nickname} - Completeness of Site Project Documents` +
          ".pdf"
      );
    },

    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, "design");
    },

    mappingDocumentSiteProject(listDsp) {
      console.log("listDSP", listDsp);
      let no = 0;
      this.dspData = [];
      listDsp.map((documentSiteProject) =>
        this.dspData.push({
          no: (no = no + 1).toString(),
          kategori: documentSiteProject.site_document_type
            ? documentSiteProject.site_document_type.category
              ? documentSiteProject.site_document_type.category.name
              : "-"
            : "-",
          item: documentSiteProject.site_document_type
            ? documentSiteProject.site_document_type.item
            : "-",
          kelengkapan: documentSiteProject.complete,
          storage: documentSiteProject.storage,
          note: documentSiteProject.note,
          description: documentSiteProject.description,
          site_document_date: documentSiteProject.site_document_date,
        })
      );
    },

    getScopes() {
      if (!arrayMoreThanOne(this.scopes)) {
        this.$store.dispatch(GET_SCOPES, {});
      }
    },

    getReports() {
      if (this.filter.code) {
        this.$store
          .dispatch(GET_REPORT_DOCUMENT_SITE_PROJECT, {
            params: {
              ...this.filter,
            },
            code: this.filter?.code,
          })
          .then(() => this.mappingDocumentSiteProject(this.currentDsp));
      } else {
        showToast("Failed", "Please Choose Project Code", "danger");
      }
    },

    setNickname(value) {
      const project = this.projectList?.find(
        (project) => project?.code === value
      );

      this.filter.code = project?.code;
      this.filter.nickname = project?.nickname;
      this.filter.contract_no = project?.contract_no;

      this.filter.revision_version = project?.dmp_rev;
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Report Completeness of Site Project Documents" },
    ]);

    //this.getProjects();
    await this.$store.dispatch(SHOW_PROJECT_INTERFACE);
    await this.$store.dispatch(SHOW_PROJECT_DESIGN);
    await this.$store.dispatch(SHOW_PROJECT_PLANNER);
    await this.$store.dispatch(SHOW_PROJECT_DRAFTER);
    await this.$store.dispatch(SHOW_PROJECT_KOOR);
    await this.$store.dispatch(SHOW_PROJECT_ENGINER);
    await this.$store.dispatch(SHOW_PROJECT_MANAGER);
    await this.$store.dispatch(SHOW_PROJECT_ADMIN);

    this.getScopes();
  },
};
</script>
