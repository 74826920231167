export const exportExcelColumn = [
  {
    label: "Category",
    field: "category"
  },
  {
    label: "Item",
    field: "item"
  },
  {
    label: "Completeness",
    field: "complete"
  },
  {
    label: "Storage",
    field: "storage"
  }
];
